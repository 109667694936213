<template>
  <section class="conOfAnnualCongressUpcoming h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <HeaderPage :headerPage="{ part1: 'Annual', part2: 'Congress' }" />
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="12">
          <div class="conOfTabs mb-3">
            <v-btn
              class="activetab"
              :to="{ name: 'annualCongressUpcoming' }"
              text
            >
              Upcoming
            </v-btn>
            <v-btn :to="{ name: 'annualCongressHistory' }" text>
              History
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <form class="conFormSubmit">
        <v-row>
          <v-col md="3" sm="6" cols="12">
            <div class="conDefaultInput">
              <v-text-field
                solo
                v-model="search.terms"
                label="Title Or Description"
                hide-details="auto"
              ></v-text-field>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="conSelectOption">
              <v-select
                outlined
                v-model="search.orderBy"
                :items="items"
                item-text="name"
                item-value="value"
                label="Order By"
                hide-details="auto"
                :menu-props="{ bottom: true, offsetY: true }"
                attach
              ></v-select>
            </div>
          </v-col>

          <v-col md="3" sm="6" cols="12">
            <div class="conDatePicker">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    v-model="search.date"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="search.date"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="confBtns">
              <v-btn
                class="srearchBnt primaryBtn mr-4"
                @click="onSearch"
                :disabled="isLoadingSearch"
                :loading="isLoadingSearch"
              >
                Search
              </v-btn>
              <v-btn class="clearBtn secondary-outline-btn" @click="onClear">
                clear
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </v-container>

    <section class="mt-3" v-if="listItems && listItems.length > 0">
      <v-container>
        <v-row v-if="isFiltered">
          <v-col cols="12">
            <div class="mainCardEvent">
              <v-card class="mx-auto" tile elevation="1">
                <!-- :src="'https://img.youtube.com/vi/' + media.url + '/0.jpg'" -->
                <div class="mainCardEvent">
                  <v-img
                    height="250"
                    :src="listItems[0].thumbnail"
                    alt="item of the Image"
                  >
                    <div class="contentMainheader">
                      <div>
                        <div class="dateSec">{{ listItems[0].date }}</div>
                        <div class="mt-3 mb-4">
                          <div class="titleSec">
                            {{ listItems[0].title }}
                          </div>
                          <div
                            class="descSec"
                            v-html="listItems[0].description"
                          ></div>
                        </div>

                        <v-btn
                          class="primaryBtn evenBtn"
                          text
                          :ripple="false"
                          :to="{
                            name: 'eventItem',
                            params: {
                              id: listItems[0].id,
                              eventType: 'upcoming',
                            },
                          }"
                        >
                          Event Details
                        </v-btn>
                      </div>
                    </div>
                  </v-img>
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            lg="3"
            md="4"
            sm="6"
            cols="12"
            v-for="(item, index) in filterItem"
            :key="index"
          >
            <div class="conofcard">
              <v-card class="mx-auto" max-width="374" tile elevation="1">
                <div class="conOfCardImg">
                  <v-img
                    height="250"
                    :src="item.thumbnail"
                    alt="item of the Image"
                  ></v-img>
                  <div class="conOfDate">{{ item.date }}</div>
                </div>

                <v-card-title class="cardTitle">{{ item.title }}</v-card-title>

                <v-card-text>
                  <div class="cardDesc" v-html="item.description"></div>
                  <div class="conOfFooterLinks d-flex justify-space-between">
                    <div>
                      <v-btn
                        class="eventsDetails"
                        text
                        :ripple="false"
                        :to="{
                          name: 'eventItem',
                          params: {
                            id: item.id,
                            eventType: 'upcoming',
                          },
                        }"
                      >
                        Event Details
                        <v-icon> mdi-arrow-right </v-icon>
                      </v-btn>
                    </div>
                    <div>
                      <!-- <v-btn icon class="eventShareBtn">
                        <v-icon>mdi-share-variant</v-icon>
                      </v-btn> -->

                      <v-menu offset-y top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="eventShareBtn"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-share-variant</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <ShareNetwork
                              class="twitterLink shareBtn_style"
                              network="twitter"
                              :url="
                                staticUrl +
                                '/annual-congress/upcoming/' +
                                item.id
                              "
                              :title="item.title"
                              :description="item.description"
                              :quote="item.description"
                              hashtags="SSBD EVENT"
                            >
                              <v-icon> mdi-twitter </v-icon>
                              Twitter
                            </ShareNetwork>
                          </v-list-item>
                          <v-list-item>
                            <ShareNetwork
                              class="facebookLink shareBtn_style"
                              network="facebook"
                              :url="
                                staticUrl +
                                '/annual-congress/upcoming/' +
                                item.id
                              "
                              :title="item.title"
                              :description="item.description"
                              :quote="item.description"
                              hashtags="SSBD EVENT"
                            >
                              <v-icon> mdi-facebook </v-icon>
                              Facebook
                            </ShareNetwork>
                          </v-list-item>
                          <v-list-item>
                            <ShareNetwork
                              class="whatsappLink shareBtn_style"
                              network="whatsapp"
                              :url="
                                staticUrl +
                                '/annual-congress/upcoming/' +
                                item.id
                              "
                              :title="item.title"
                              :description="item.description"
                              :quote="item.description"
                              hashtags="SSBD EVENT"
                            >
                              <v-icon> mdi-whatsapp </v-icon>
                              whatsapp
                            </ShareNetwork>
                          </v-list-item>
                          <v-list-item>
                            <v-btn
                              text
                              @click="
                                copuURL(
                                  staticUrl +
                                    '/annual-congress/upcoming/' +
                                    item.id
                                )
                              "
                              class="copyLink shareBtn_style"
                            >
                              <v-icon> mdi-content-copy </v-icon>
                              copy link
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="pagination.total > pagination.per_page">
          <v-col cols="12">
            <div class="text-center">
              <v-pagination
                v-model="pagination.current_page"
                :length="pagination.last_page"
                @input="goToPage(pagination.current_page)"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import HeaderPage from "@/modules/shared/components/header-pages";
import { ApiService } from "@/modules/shared/services/api";
import { websiteStaticURL } from "@/modules/shared/model/constants";
let apiServices = new ApiService();
export default {
  data: () => ({
    dialog: false,
    name: "",
    menu: false,
    staticUrl: websiteStaticURL,
    items: [
      {
        name: "newest",
        value: 1,
      },
      {
        name: "oldest",
        value: 2,
      },
    ],
    search: {
      terms: "",
      date: "",
      orderBy: null,
    },
    updatedFilter: null,
    pagination: {
      current_page: 1,
    },
    listItems: null,
    isLoading: false,
    isLoadingSearch: false,
    isFiltered: true,
    updatedCurrent_page: null,
  }),
  components: {
    EmptyState,
    HeaderPage,
  },
  methods: {
    copuURL(url) {
      navigator.clipboard.writeText(url);
    },
    onClear() {
      // this.pagination.current_page = 1;
      this.search.terms = "";
      this.search.date = "";
      this.search.orderBy = null;
      this.pathByOaramSearch();
    },
    inzializeForm() {
      this.pagination.current_page = this.$route.query.current_page || 1;
      this.search.terms = this.$route.query.terms || "";
      this.search.date = this.$route.query.date || "";
      this.search.orderBy = parseInt(this.$route.query.orderBy) || null;
    },
    pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (
        JSON.stringify(this.updatedFilter) != JSON.stringify(query) ||
        this.updatedCurrent_page != this.pagination.current_page
      ) {
        this.$router.replace({
          name: "annualCongressUpcoming",
          query: { current_page: this.pagination.current_page, ...query },
        });
        this.updatedFilter = query;
        this.updatedCurrent_page = this.pagination.current_page;
      }
    },
    onSearch() {
      this.pagination.current_page = 1;
      this.pathByOaramSearch();
    },
    goToPage() {
      this.pathByOaramSearch();
      this.getListItems();
      window.scrollTo(0, 0);
    },
    getListItems() {
      this.listItems = [];
      this.isLoading = true;
      this.isLoadingSearch = true;

      apiServices
        .post(
          "/events/upcoming?page=" + this.pagination.current_page,
          this.search
        )
        .then((res) => {
          if (res) {
            this.listItems = res.data;
            this.sliceFirstItem();
            this.pagination = res.meta;
            this.isLoading = false;
            this.isLoadingSearch = false;
          } else {
            this.isLoading = false;
            this.isLoadingSearch = false;
          }
        });
    },
    checkIsFiltered() {
      if (this.search.terms || this.search.orderBy || this.search.date)
        this.isFiltered = false;
      else this.isFiltered = true;
    },
    sliceFirstItem() {
      if (this.isFiltered) {
        this.filterItem = this.listItems.splice(1);
      } else {
        this.filterItem = this.listItems;
      }
    },
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.inzializeForm();
        this.checkIsFiltered();
        this.getListItems();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_upcoming.scss";
</style>
